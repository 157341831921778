/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */
 
 /*jshint esversion: 6 */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
	      
	      // Tippy
	      
	      const cbFormSearch = document.getElementById('cb-form-search');
	      
	      tippy('#cb-btn-search-tippy', {
		      allowHTML: true,
        	content: cbFormSearch.innerHTML,
        	interactive: 'true',
        	maxWidth: 'none',
        	offset: [0, 20],
        	placement: 'left',
        	trigger: 'click',
        	theme: 'cb',
      	});
        
        // AOS
        AOS.init({
	        once: true
        });
        
				//Hamburgers
        var $hamburger = $(".hamburger");
        
			  $hamburger.on("click", function(e) {
			    $hamburger.toggleClass("is-active");
			    $(".cb-menu").toggleClass("cb-menu-active");
					$("body").toggleClass("nav-open");
			  });
			  
        // Bootstrap Select        
        $.fn.selectpicker.Constructor.BootstrapVersion = '4';
        
        $('select').selectpicker({
	        actionsBox: true,
	        width: '100%'
        });
        
        $("p a").addClass("hvr-underline-from-left");
      }
    },
    
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
				var scroll = new SmoothScroll('a[href*="#"]', {
					updateURL: false
				});
      }
    },
    
    'tax_categories': {
      init: function() {
        $(".active.menu-all").removeClass("active");
      },
      finalize: function() {}
    },
    
    // Property - Archive
    'propertyhive': {
      init: function() {
        $(".cb-form-location select").attr("title", "Parish(es)");
        $(".cb-form-catchment select").attr("title", "Catchment(s)");
        $(".cb-form-market select").attr("title", "Market(s)");
        $(".cb-form-type select").attr("title", "Type(s)");
      },
      finalize: function() {
		$(window).on('resize', function() {
			if($(window).width() > 1279) {
				$('#cb-property-search-inner').removeClass('show');
			}
		});
      }
    },
   
    // Property - Single
    'single_property': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
        
        // SmoothScroll
		var scroll = new SmoothScroll('a[href*="#"]', {
			updateURL: false
		});
		
		// Swiper
		
		var swiper_property_gallery = new Swiper("#cb-single-property-gallery", {
			slidesPerView: "1",
			loop: true,
			centeredSlides: true,
			spaceBetween: 0,
			navigation: {
				nextEl: ".swiper-button-next",
				prevEl: ".swiper-button-prev",
			},
	        breakpoints: {
				992: {
					slidesPerView: 1.25,
					spaceBetween: 32,
          		}
        	},
		});
		
		var swiper_property_team = new Swiper(".cb-single-property-team-carousel", {
			autoplay: {
				delay: 2000,
				disableOnInteraction: false,
			},
			slidesPerView: "1",
			speed: 4000,
			loop: true,
			spaceBetween: 40,
	        breakpoints: {
				992: {
					slidesPerView: 3,
          		},
		  		768: {
		  			slidesPerView: 2,
          		},
        	},
		});
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
